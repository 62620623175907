import React, {useEffect, useState} from "react";
import {
    Avatar,
    Box,
    IconButton,
    Menu,
    MenuItem,
    Paper,
    Typography
} from "@mui/material";
import {
    CompetitionTableClientDto,
    ContestClientDto,
    IAccountClientDto
} from "../../../api/NswagClient";
import {ContestFormatter} from "../ContestFormatter";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {CompetitionApplyGameResultDialog} from "./CompetitionApplyGameResultDialog";

export function CompetitionTables(props: {
    account?: IAccountClientDto,
    contest: ContestClientDto,
    onChange: (contest: ContestClientDto) => void
}) {

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [isTableDialogOpen, setIsTableDialogOpen] = useState(false);
    const [selectedTable, setSelectedTable] = useState<CompetitionTableClientDto>();

    useEffect(() => {
        if (selectedTable) {
            const table = props.contest.competition?.tables?.find(x => x.tableId === selectedTable.tableId);
            setSelectedTable(table);
        }
    }, [props.contest]);

    const handleOpenTableContextMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseTableContextMenu = () => {
        setSelectedTable(undefined);
        setAnchorEl(null);
    };

    const contest = props.contest;
    const isOrganizer = props.account && props.account.userId === contest.organizer!.userId;
    const participantsMap = new Map(contest.participants!.map(x => [x.participantId, x]));


    return (
        <Paper elevation={0} sx={{ mt: 1 }}>

            <Box sx={{ p: 2 }}>

                <Box>
                    <Typography sx={{ fontSize: 12 }}
                                color="text.secondary"
                                noWrap>
                        Столы
                    </Typography>
                </Box>

                <Box sx={{ pt: 1 }}>
                    {
                        contest.competition?.tables?.map(table =>
                            <Box key={table.tableId} sx={{ pt: 1, pb: 2 }}>

                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flexGrow: 1,
                                    gap: 2,
                                    mt: 1
                                }}>

                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'center' }}>
                                        <Typography sx={{ color: 'text.secondary' }}
                                                    variant='body2'>Стол {table.number}</Typography>
                                        <Box sx={{ flexGrow: 1 }}></Box>
                                        {
                                            isOrganizer &&
                                            <>
                                                <IconButton edge="end"
                                                            onClick={e => {
                                                                setSelectedTable(table);
                                                                handleOpenTableContextMenu(e)
                                                            }}>
                                                    <MoreVertIcon/>
                                                </IconButton>

                                                <Menu
                                                    id="basic-menu"
                                                    anchorEl={anchorEl}
                                                    open={Boolean(anchorEl)}
                                                    onClose={handleCloseTableContextMenu}
                                                >
                                                    <MenuItem onClick={() => {
                                                        setIsTableDialogOpen(true);
                                                    }}>
                                                        Ввести счет
                                                    </MenuItem>
                                                </Menu>

                                                {
                                                    selectedTable &&
                                                    <CompetitionApplyGameResultDialog contest={contest}
                                                                                      table={selectedTable}
                                                                                      open={isTableDialogOpen}
                                                                                      onClose={() => {
                                                                                          handleCloseTableContextMenu();
                                                                                          setIsTableDialogOpen(false);
                                                                                      }}
                                                                                      onChange={props.onChange}/>
                                                }
                                            </>
                                        }
                                    </Box>

                                    {
                                        table.match &&
                                        <>
                                            <Box sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                flexGrow: 1,
                                                gap: 2
                                            }}>

                                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        gap: 2,
                                                        alignItems: 'center'
                                                    }}>
                                                        <Avatar sx={{ width: 24, height: 24 }}
                                                                src={participantsMap.get(table.match.competitor1Id)?.avatar}/>
                                                        <Typography>{ContestFormatter.formatParticipantName(participantsMap.get(table.match.competitor1Id))}</Typography>
                                                    </Box>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        gap: 2,
                                                        alignItems: 'center'
                                                    }}>
                                                        <Avatar sx={{ width: 24, height: 24 }}
                                                                src={participantsMap.get(table.match.competitor2Id)?.avatar}/>
                                                        <Typography>{ContestFormatter.formatParticipantName(participantsMap.get(table.match.competitor2Id))}</Typography>
                                                    </Box>
                                                </Box>

                                                <Box sx={{ flexGrow: 1 }}></Box>

                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center'
                                                    }}>
                                                    <Typography variant='h5' sx={{ color: 'secondary.main' }}>
                                                        {
                                                            `${table.match.score?.competitor1} : ${table.match.score?.competitor2}`
                                                        }
                                                    </Typography>
                                                    <Typography sx={{ color: 'text.secondary', fontSize: 12 }}>
                                                        {
                                                            table.match.games!.map(x => `${x.competitor1Points}:${x.competitor2Points}`).join(', ')
                                                        }
                                                    </Typography>
                                                </Box>

                                            </Box>
                                        </>
                                    }

                                    {
                                        !table.match &&
                                        <Typography sx={{ color: 'text.secondary' }}>Нет матчей</Typography>
                                    }

                                </Box>

                            </Box>
                        )
                    }
                </Box>

            </Box>

        </Paper>
    );
}
