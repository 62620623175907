import React from "react";
import {Box, FormControl, FormControlLabel, FormHelperText, Radio, RadioGroup, Typography} from "@mui/material";
import {ContestClientDto, ContestFormatTypeClientDto} from "../../../api/NswagClient";

export function FormatTypeStep(props: {
    contest: ContestClientDto,
    format: ContestFormatTypeClientDto,
    onChange: (format: ContestFormatTypeClientDto) => void
}) {

    return (
        <Box sx={{ p: 2 }}>
            <Box>
                <Typography variant='h5'>Формат</Typography>
            </Box>

            <Box sx={{ mt: 2 }}>
                <FormControl>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        value={props.format}
                        onChange={x => props.onChange(x.target.value as ContestFormatTypeClientDto)}
                    >
                        <FormControlLabel value={ContestFormatTypeClientDto.RoundRobin} control={<Radio/>}
                                          label="Круговой"/>
                        {/*<FormControlLabel value={ContestFormatTypeClientDto.Swiss} control={<Radio/>}*/}
                        {/*                  label="Швейцарская"/>*/}
                        <FormControlLabel value={ContestFormatTypeClientDto.SingleElimination} control={<Radio/>}
                                          label="Single elimination"/>
                        <FormControlLabel value={ContestFormatTypeClientDto.DoubleElimination} control={<Radio/>}
                                          label="Double elimination"/>
                    </RadioGroup>
                </FormControl>
            </Box>

            <Box sx={{ mt: 2 }}>

                <FormHelperText>
                    {
                        props.format === ContestFormatTypeClientDto.RoundRobin &&
                        <>
                            Каждый играет с каждым в одной или нескольких группах, посев в группах по рейтингу,
                            распределение мест по очкам
                        </>
                    }

                    {
                        props.format === ContestFormatTypeClientDto.Swiss &&
                        <>
                            Игры с распределением мест, посев по рейтингу
                        </>
                    }

                    {
                        props.format === ContestFormatTypeClientDto.SingleElimination &&
                        <>
                            Игры до одного поражения, посев по рейтингу
                        </>
                    }

                    {
                        props.format === ContestFormatTypeClientDto.DoubleElimination &&
                        <>
                            Игры до двух поражений, посев по рейтингу
                        </>
                    }
                </FormHelperText>

            </Box>
        </Box>
    );
}